import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  MuiThemeProvider,
  Paper,
  Backdrop,
  CircularProgress,
  CssBaseline,
  Box,
  TextField,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Grid,
  Button,
  Container,
  Chip,
} from "@material-ui/core";
import { ColorMode, myTheme } from "../myColorTheme";
import { useState } from "react";
import {
  AccountCircle,
  Brightness6Sharp,
  ExitToApp,
  Home,
  Refresh,
} from "@material-ui/icons";
import { auth } from "../Firebase";
import { signInWithRedirect } from "firebase/auth";
import { getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import axios, { AxiosRequestConfig } from "axios";
import SimpleSnackbar from "../SimpleSnackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    chip: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

interface Config {
  low_limit: number;
  high_limit: number;
  notification_interval: number;
}

function Settings() {
  const classes = useStyles();
  const [colorMode, setColorMode] = useState<ColorMode>(
    localStorage.getItem("colorMode") === "dark" ? "dark" : "light"
  );
  const colorTheme = myTheme;
  const _startY = useRef(0);
  const [refresh, setRefresh] = useState(false);
  const handleClose = () => {
    setRefresh(false);
  };
  const toggleColorMode = () => {
    if (colorMode === "dark") {
      localStorage.setItem("colorMode", "light");
      setColorMode("light");
    } else {
      localStorage.setItem("colorMode", "dark");
      setColorMode("dark");
    }
  };
  const [logined, setLogin] = useState(false);
  const [loginButton, setLoginButton] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setLogin(true);
    } else {
      setLoginButton(true);
    }
  });
  const handleLogin = () => {
    var provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider)
    .then((result) => {
      console.log(result);
    }).catch((reason) => {
      console.error(reason);
    });
  };
  //
  // コンフィグデータの読み込み
  //
  const [lowLimit, setLowLimit] = useState(22);
  const [highLimit, setHighLimit] = useState(35);
  const [notificationInterval, setNotificationInterval] = useState(60);
  useEffect(() => {
    if (process.env.REACT_APP_API_ENDPOINT !== undefined) {
      const uri = process.env.REACT_APP_API_ENDPOINT + "/config";
      axios.get<Config>(uri).then((response) => {
        if (response.status === 200) {
          setLowLimit(response.data.low_limit);
          setHighLimit(response.data.high_limit);
          setNotificationInterval(response.data.notification_interval);
        }
      });
    }
  }, []);
  const formLowLimitValue = useRef<null | HTMLInputElement>(null);
  const formHighLimitValue = useRef<null | HTMLInputElement>(null);
  const formNotificationIntervalValue = useRef<null | HTMLInputElement>(null);
  const handleApplySettings = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      formLowLimitValue.current == null &&
      formHighLimitValue.current == null &&
      formNotificationIntervalValue.current == null
    ) {
      return;
    }
    const formLowLimit = Number(formLowLimitValue.current?.value);
    const formHighLimit = Number(formHighLimitValue.current?.value);
    const formNotificationInterval = Number(
      formNotificationIntervalValue.current?.value
    );
    if (
      isNaN(formLowLimit) ||
      isNaN(formHighLimit) ||
      isNaN(formNotificationInterval)
    ) {
      return;
    }
    if (30 < formLowLimit || formLowLimit < 0) {
      alert("最低気温は0~30までにしてください");
      return;
    }
    if (35 < formHighLimit || formHighLimit < 20) {
      alert("最高気温は20~35までにしてください");
      return;
    }
    if (1440 < formNotificationInterval || formNotificationInterval < 10) {
      alert("通知間隔は10分から1440分(24時間)までにしてください");
      return;
    }
    const f = async () => {
      const jwt_token = await auth.currentUser?.getIdToken(true);
      if (jwt_token) {
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        };
        const param: Config = {
          low_limit: formLowLimit,
          high_limit: formHighLimit,
          notification_interval: formNotificationInterval,
        };
        if (process.env.REACT_APP_API_ENDPOINT !== undefined) {
          const uri = process.env.REACT_APP_API_ENDPOINT + "/config";
          axios
            .post(uri, param, config)
            .then((response) => {
              setRefresh(true);
              setSnack(true);
            })
            .catch(alert);
        }
      }
    };
    f();
  };
  useEffect(() => {
    const body = document.querySelector("body");

    body?.addEventListener(
      "touchstart",
      (e) => {
        _startY.current = e.touches[0].pageY;
      },
      { passive: true }
    );
    body?.addEventListener(
      "touchmove",
      (e) => {
        const y = e.touches[0].pageY;
        if (
          document.scrollingElement?.scrollTop === 0 &&
          y - _startY.current > 400 &&
          !document.body.classList.contains("refreshing")
        ) {
          _startY.current = 99999;
          setRefresh(true);
        }
      },
      { passive: true }
    );
  }, []);
  const [backdrop, setBackdrop] = useState(false);
  useEffect(() => {
    if (refresh) {
      if (process.env.REACT_APP_API_ENDPOINT !== undefined) {
        const uri = process.env.REACT_APP_API_ENDPOINT + "/config";
        axios
          .get<Config>(uri)
          .then((response) => {
            if (response.status === 200) {
              if (formLowLimitValue.current) {
                formLowLimitValue.current.value =
                  response.data.low_limit.toString();
              }
              if (formHighLimitValue.current) {
                formHighLimitValue.current.value =
                  response.data.high_limit.toString();
              }
              if (formNotificationIntervalValue.current) {
                formNotificationIntervalValue.current.value =
                  response.data.notification_interval.toString();
              }
            }
          })
          .finally(() => {
            setRefresh(false);
          });
      }
    }
    setBackdrop(true);
    const intervalId = setInterval(() => {
      setBackdrop(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [refresh]);

  const [snack, setSnack] = useState(false);
  useEffect(() => {
    if (snack) {
      const intervalId = setInterval(() => {
        setSnack(false);
      }, 10000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [snack]);
  return (
    <MuiThemeProvider theme={colorTheme(colorMode)}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            温度管理
          </Typography>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={() => {
              setRefresh(true);
            }}
          >
            <Refresh />
          </IconButton>
          {logined && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              href="/logout"
            >
              <ExitToApp />
            </IconButton>
          )}
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={toggleColorMode}
          >
            <Brightness6Sharp />
          </IconButton>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            href="/"
          >
            <Home />
          </IconButton>
        </Toolbar>
      </AppBar>
      {logined && (
        <Container maxWidth="sm">
          <form onSubmit={handleApplySettings}>
            <Grid container spacing={3} alignItems="center" justify="center">
              <Grid item xs={12}>
                <Box mt={3} ml={2}>
                  温度設定
                </Box>
                <TableContainer
                  component={Paper}
                  elevation={3}
                  className={classes.paper}
                >
                  <Table>
                    <TableRow>
                      <TableCell>この温度を下回ったら通知</TableCell>
                      <TableCell>
                        <TextField
                          id="low-limit"
                          label="最低気温"
                          defaultValue={lowLimit}
                          variant="outlined"
                          inputRef={formLowLimitValue}
                          type="number"
                          inputProps={{ step: "any" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>この温度を上回ったら通知</TableCell>
                      <TableCell>
                        <TextField
                          id="high-limit"
                          label="最高気温"
                          defaultValue={highLimit}
                          variant="outlined"
                          inputRef={formHighLimitValue}
                          type="number"
                          inputProps={{ step: "any" }}
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Box mt={3} ml={2}>
                  詳細設定
                </Box>
                <TableContainer
                  component={Paper}
                  elevation={3}
                  className={classes.paper}
                >
                  <Table>
                    <TableRow>
                      <TableCell>次の通知までの時間間隔(分)</TableCell>
                      <TableCell>
                        <TextField
                          id="interval"
                          label="通知間隔"
                          defaultValue={notificationInterval}
                          variant="outlined"
                          inputRef={formNotificationIntervalValue}
                          type="number"
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="center">
                  <Button variant="outlined" href="/">
                    キャンセル
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    name="submit"
                    type="submit"
                  >
                    適用
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
          {snack && <SimpleSnackbar />}
        </Container>
      )}
      {!logined && !loginButton && (
        <>
          <Paper color="inherit">読み込み中...</Paper>
        </>
      )}
      {!logined && loginButton && (
        <>
          <Toolbar />
          <div className={classes.chip}>
            <Chip
              label="ログイン"
              color="primary"
              icon={<AccountCircle />}
              component={Paper}
              elevation={3}
              onClick={handleLogin}
            />
          </div>
        </>
      )}
      <Backdrop
        className={classes.backdrop}
        open={refresh || backdrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>
  );
}

export default Settings;
