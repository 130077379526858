import React from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ThermoResponseData } from "../ApiInterface";
import LensIcon from "@material-ui/icons/Lens";

function getFormattedDate(date: Date) {
  date.setHours(date.getHours() - 9);
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2)
  );
}

function formatRawData2table(data: Array<ThermoResponseData>) {
  const extracts = data.map((value: ThermoResponseData) => ({
    id: value.id,
    datetime: getFormattedDate(new Date(value.created_at)),
    temp: value.temperature,
    humid: value.humidity,
  }));
  return extracts;
}

function getDiscomfortIndex(t: number, h: number): number {
  return 0.81 * t + 0.01 * h * (0.99 * t - 14.3) + 46.3;
}

enum DICode {
  Cold = 0,
  Cool = 1,
  NotBad = 2,
  Good = 3,
  NotWarm = 4,
  Warm = 5,
  Hot = 6,
  TooHot = 7,
}

let DIColor: Array<string> = [
  "#0000FF",
  "#00FFFF",
  "#7FFFD4",
  "#00FF00",
  "#6B8E23",
  "#FFD700",
  "#FF4500",
  "#FF0000",
];

let DIJa: Array<string> = [
  "寒い",
  "肌寒い",
  "快適 (-)",
  "快適",
  "快適 (+)",
  "やや暑い",
  "暑い",
  "暑すぎる",
];

function getFeelingTemperature(di: number): number {
  if (di < 55) {
    return DICode.Cold;
  } else if (di < 60) {
    return DICode.Cool;
  } else if (di < 65) {
    return DICode.NotBad;
  } else if (di < 70) {
    return DICode.Good;
  } else if (di < 75) {
    return DICode.NotWarm;
  } else if (di < 80) {
    return DICode.Warm;
  } else if (di < 85) {
    return DICode.Hot;
  } else {
    return DICode.TooHot;
  }
}

interface DIProps {
  temp: number;
  humid: number;
}

const DIJSX = (props: DIProps) => {
  const dicode = getFeelingTemperature(
    getDiscomfortIndex(props.temp, props.humid)
  );
  return (
    <>
      <Box style={{ alignItems: "center" }}>
        <LensIcon style={{ color: DIColor[dicode] }} />
        <br />
        <Typography style={{ fontSize: "9px" }}>{DIJa[dicode]}</Typography>
      </Box>
    </>
  );
};

function getLastDate(data: Array<ThermoResponseData>): string {
  const date = new Date(data[0].created_at);
  date.setHours(date.getHours() - 9);
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2)
  );
}

export interface DetailTabProps {
  data: Array<ThermoResponseData>;
  color?: "inherit" | "primary" | "secondary" | undefined;
}

export default function DetailTab(props: DetailTabProps) {
  const forTableData = formatRawData2table(props.data);

  return (
    <Container color={props.color}>
      <Typography align="right">
        最終取得日時: {getLastDate(props.data)}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>日時</TableCell>
              <TableCell>
                温度
                <br />℃
              </TableCell>
              <TableCell>
                湿度
                <br />
                RH %
              </TableCell>
              <TableCell>指数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forTableData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.datetime}</TableCell>
                <TableCell>{row.temp}</TableCell>
                <TableCell>{row.humid}</TableCell>
                <TableCell>
                  <DIJSX temp={row.temp} humid={row.humid} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
