import React from "react";
import { useHistory } from "react-router";
import { auth } from "../Firebase";

const Logout = () => {
  const history = useHistory();
  auth.onAuthStateChanged((user) => {
    if (user) {
      auth.signOut();
    } else {
      history.push("/");
    }
  });
  return <></>;
};

export default Logout;
