import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Container,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { ExpandLessRounded, ExpandMore } from "@material-ui/icons";
import { ThermoResponseData } from "../ApiInterface";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graph: {
      padding: theme.spacing(2),
      textAlign: "center",
      alignItems: "center",
      height: "240px",
    },
    svg: {
      textAlign: "center",
      alignItems: "center",
      display: "block",
      margin: "auto",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  })
);

function timestamp2time(timestamp: number): string {
  var date = new Date(timestamp * 1000);
  const hour = date.getHours();
  const minute = ("0" + date.getMinutes()).substr(-2);
  return hour + ":" + minute;
}

interface PlotGraphProps {
  title: string;
}

/*
function PlotGraph(props: LineSeriesProps & PlotGraphProps) {
  const classes = useStyles();
  return (
    <div className={classes.graph}>
      <XYPlot
        width={200}
        height={200}
        className={classes.svg}
        xType="time"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickTotal={1} tickFormat={timestamp2time} />
        <YAxis title={props.title} />
        <LineSeries
          className="first-series"
          curve={"curveMonotoneX"}
          data={props.data}
          style={{ fill: "none" }}
        />
      </XYPlot>
    </div>
  );
}

function PlotHumidGraph(props: LineSeriesProps & PlotGraphProps) {
  const classes = useStyles();
  return (
    <div className={classes.graph}>
      <XYPlot
        width={200}
        height={200}
        className={classes.svg}
        xType="time"
        yDomain={[0, 100]}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickTotal={1} tickFormat={timestamp2time} />
        <YAxis title={props.title} />
        <LineSeries
          className="first-series"
          curve={"curveMonotoneX"}
          data={props.data}
          style={{ fill: "none" }}
        />
      </XYPlot>
    </div>
  );
}
*/

function getLastDate(data: Array<ThermoResponseData>): string {
  const date = new Date(data[0].created_at);
  date.setHours(date.getHours() - 9);
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2)
  );
}

function getJST(date: Date) {
  date.setHours(date.getHours() - 9);
  return date;
}

function formatRawData2temp(data: Array<ThermoResponseData>) {
  const extracts = data.map((value: ThermoResponseData) => ({
    x: getJST(new Date(value.created_at)).getTime(),
    y: value.temperature,
  }));
  return extracts;
}

function formatRawData2humid(data: Array<ThermoResponseData>) {
  const extracts = data.map((value: ThermoResponseData) => ({
    x: getJST(new Date(value.created_at)).getTime(),
    y: value.humidity,
  }));
  return extracts;
}

export interface MainTabProps {
  data: Array<ThermoResponseData>;
  color?: "inherit" | "primary" | "secondary" | undefined;
}

export default function MainTab(props: MainTabProps) {
  const classes = useStyles();
  const [expandedTemp, setExpandedTemp] = React.useState(false);
  const [expandedHumid, setExpandedHumid] = React.useState(false);

  const handleExpandTempClick = () => {
    setExpandedTemp(!expandedTemp);
  };
  const handleExpandHumidClick = () => {
    setExpandedHumid(!expandedHumid);
  };

  const forTempPlotData = formatRawData2temp(props.data);
  const forHumidPlotData = formatRawData2humid(props.data);

  return (
    <Container color={props.color}>
      <Typography align="right">
        最終取得日時: {getLastDate(props.data)}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs>
          <Card>
            <CardContent color={props.color}>
              <Typography gutterBottom variant="h5" component="h2">
                温度
              </Typography>
              <Typography variant="h2" component="h2" align="center">
                {props.data[0].temperature === 9999
                  ? "--"
                  : props.data[0].temperature}
                ℃
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: ExpandLessRounded,
                })}
                onClick={handleExpandTempClick}
                aria-expanded={expandedTemp}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
            <Collapse in={expandedHumid} timeout="auto" unmountOnExit>
              <div>Not implemented</div>
            </Collapse>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                湿度
              </Typography>
              <Typography variant="h2" component="h2" align="center">
                {props.data[0].humidity === 9999
                  ? "--"
                  : props.data[0].humidity}
                %
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: ExpandLessRounded,
                })}
                onClick={handleExpandHumidClick}
                aria-expanded={expandedHumid}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
            <Collapse in={expandedHumid} timeout="auto" unmountOnExit>
              <div>Not implemented</div>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
