import React, { useEffect, useRef } from "react";
import "./App.css";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  MuiThemeProvider,
  Paper,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  CssBaseline,
} from "@material-ui/core";
import SettingsSharpIcon from "@material-ui/icons/Settings";
import { ColorMode, myTheme } from "./myColorTheme";
import { useState } from "react";
import MainTab from "./guest/MainTab";
import { Brightness6Sharp, Refresh } from "@material-ui/icons";
import DetailTab from "./guest/DetailTab";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ThermoResponseData } from "./ApiInterface";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      height: "150px",
    },
    tab: {
      flexGrow: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

function App() {
  const classes = useStyles();
  const [colorMode, setColorMode] = useState<ColorMode>(
    localStorage.getItem("colorMode") === "dark" ? "dark" : "light"
  );
  const [tab, setTab] = useState(0);
  const colorTheme = myTheme;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  //
  // データの読み込み
  //
  const [thermoData, setThermoData] = useState<Array<ThermoResponseData>>([
    {
      id: 1,
      created_at: "2000-01-01T00:00:00.000000Z",
      temperature: 9999,
      humidity: 9999,
      pressure: 9999,
    },
  ]);
  useEffect(() => {
    var rawData: Array<ThermoResponseData> = [];
    if (process.env.REACT_APP_API_ENDPOINT !== undefined) {
      const uri = process.env.REACT_APP_API_ENDPOINT + "/thermo";
      axios.get<Array<ThermoResponseData>>(uri).then((response) => {
        if (response.status === 200) {
          response.data.forEach((resData) => {
            rawData.push(resData);
          });
          if (rawData.length != 0) {
            setThermoData(rawData);
          }
        }
      });
    }
  }, []);
  const _startY = useRef(0);
  const [refresh, setRefresh] = useState(false);
  const handleClose = () => {
    setRefresh(false);
  };
  const toggleColorMode = () => {
    if (colorMode === "dark") {
      localStorage.setItem("colorMode", "light");
      setColorMode("light");
    } else {
      localStorage.setItem("colorMode", "dark");
      setColorMode("dark");
    }
  };
  const history = useHistory();
  const settingsHandle = () => {
    history.push("/settings");
  };
  useEffect(() => {
    const body = document.querySelector("body");

    body?.addEventListener(
      "touchstart",
      (e) => {
        _startY.current = e.touches[0].pageY;
      },
      { passive: true }
    );
    body?.addEventListener(
      "touchmove",
      (e) => {
        const y = e.touches[0].pageY;
        if (
          document.scrollingElement?.scrollTop === 0 &&
          y - _startY.current > 400 &&
          !document.body.classList.contains("refreshing")
        ) {
          _startY.current = 99999;
          setRefresh(true);
        }
      },
      { passive: true }
    );
  }, []);
  useEffect(() => {
    if (refresh) {
      const intervalId = setInterval(() => {
        setRefresh(false);
      }, 1000);
      var rawData: Array<ThermoResponseData> = [];
      if (process.env.REACT_APP_API_ENDPOINT !== undefined) {
        const uri = process.env.REACT_APP_API_ENDPOINT + "/thermo";
        axios.get<Array<ThermoResponseData>>(uri).then((response) => {
          if (response.status === 200) {
            response.data.forEach((resData) => {
              rawData.push(resData);
            });
            setThermoData(rawData);
          }
        });
      }
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [refresh]);

  return (
    <MuiThemeProvider theme={colorTheme(colorMode)}>
      <CssBaseline />
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            温度計🦜
          </Typography>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={() => {
              setRefresh(true);
            }}
          >
            <Refresh />
          </IconButton>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={toggleColorMode}
          >
            <Brightness6Sharp />
          </IconButton>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={settingsHandle}
          >
            <SettingsSharpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper square className={classes.tab}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          centered
        >
          <Tab label="最新" />
          <Tab label="詳細" />
        </Tabs>
      </Paper>
      <Toolbar />
      {tab === 0 && <MainTab data={thermoData} color="primary" />}
      {tab === 1 && <DetailTab data={thermoData} color="primary" />}
      <Backdrop
        className={classes.backdrop}
        open={refresh}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>
  );
}

export default App;
