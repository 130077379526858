import { createMuiTheme } from "@material-ui/core";
import teal from "@material-ui/core/colors/teal";
import green from "@material-ui/core/colors/green";

export type ColorMode = "dark" | "light";

export const myTheme = (mode: ColorMode) =>
  createMuiTheme({
    palette: {
      primary: teal,
      secondary: green,
      type: mode,
    },
  });
